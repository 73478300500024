import React from 'react';
import BudgetContainer from './BudgetContainer';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import { css } from 'glamor';

const wrapper = css({
  position: 'relative',
  minHeight: '100vh',
  margin: 0,
  overflowX: 'hidden',
  overflowY: 'auto',
});

export default function BudgetPage(ownProps) {
  return (
    <div className={wrapper}>
      <Header noLink />
      <BudgetContainer id={ownProps?.match?.params?.id} />
    </div>
  );
}
